/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("jquery");
import $ from "jquery";
window.jQuery = $;
window.$ = $;
require("@rails/actiontext");
require("select2");
require("./jstree.js");
import "./jstree_init.js";
require("./cocoon.js.erb");
require("jquery-contextmenu");
require("./direct_upload");

import "materialize-css/dist/js/materialize";

// VUE

import TurbolinksAdapter from "vue-turbolinks";
import Vue from "vue/dist/vue.esm";
import VueAuthImage from "vue-auth-image";
import App from "../app.vue";

import Document from "../document.vue";

Vue.use(VueAuthImage);
Vue.use(TurbolinksAdapter);

Vue.component("app", App);
Vue.component("document", Document);

const axios = require("axios");
axios.defaults.headers.common["Authorization"] = "t67gyue239uhjiu23oekj2389s3";

var jstree;

function hideAlert() {
  $(".alert")
    .fadeTo(5000, 0.5)
    .slideUp(500, function() {
      $(".alert").remove();
    });
}

document.addEventListener("turbolinks:load", () => {
  window.app = new Vue({
    el: ".vuejs",
    data: {
      root_categories: [],
      current_category: null,
      current_root_category: null,
      documents: [],
      loading: false
    },

    mounted() {
      axios.get("/api/categories").then(response => {
        this.root_categories = response.data;
        this.current_root_category = response.data[0];
      });
    },

    watch: {
      current_root_category: function(new_value, original_value) {
        this.current_category = null;

        $("#category_tree").jstree("destroy");

        var vue_app = this;
        $("#category_tree")
          .on("activate_node.jstree", function(e, data) {
            vue_app.current_category = data.node.original;
          })
          .on("close_node.jstree", function(e, data) {
            vue_app.current_category = data.node.original;
          })
          .on("select_node.jstree", function(e, data) {
            vue_app.current_category = data.node.original;
          })
          .on("hover_node.jstree", function(e, data) {
            var $node_dom = $("li#" + data.node.id + " a:first ");
            if ($node_dom.prop("title") === "") {
              $node_dom.html($("<span>").html($node_dom.html()));
              $node_dom.prop("title", data.node.text);
            }

            $node_dom.append(
              `<span class='category-menu' id='category-menu-${data.node.id}'><i class='material-icons'>more_vert</i></span>`
            );

            $.contextMenu({
              selector: "#category-menu-" + data.node.id,
              trigger: "left",
              node_id: data.node.id,
              callback: function(key, options) {
                if (key === "new folder") {
                  $.ajax({
                    url: "/admin/categories/new",
                    dataType: "script",
                    data: {
                      category: {
                        parent_id: options.node_id
                      }
                    }
                  });
                } else if (key === "delete") {
                  $.ajax({
                    url: "/admin/categories/" + options.node_id,
                    dataType: "script",
                    method: "DELETE"
                  });
                } else if (key === "edit") {
                  $.ajax({
                    url: "/admin/categories/" + options.node_id + "/edit",
                    dataType: "script"
                  });
                }
              },
              items: {
                "new folder": { name: "New folder", value: data.node.id },
                edit: { name: "Edit", value: data.node.id },
                delete: { name: "Delete", value: data.node.id }
              }
            });
          })
          .on("dehover_node.jstree", function(e, data) {
            var $node_dom = $("li#" + data.node.id + " a:first ");

            if ($node_dom.prop("title") != "") {
              $node_dom.html($node_dom.find("span").html());
              $node_dom.prop("title", "");
            }

            $.contextMenu("destroy", ".category-menu");
            $(".category-menu").remove();
          })
          .jstree({
            core: {
              data: {
                url: function(node) {
                  return "/api/categories?online=1";
                },
                headers: { Authorization: "t67gyue239uhjiu23oekj2389s3" },
                data: function(node) {
                  return {
                    parent_id: node.id === "#" ? new_value.id : node.id
                  };
                }
              }
            }
          });
      },
      current_category: function(value) {
        this.reload_category();
      }
    },

    methods: {
      reload_category: function() {
        this.documents = [];
        if (this.current_category) {
          this.loading = true;

          var remote_url =
            "/api/categories/" + this.current_category.id + "/documents";
          axios.get(remote_url).then(response => {
            this.documents = response.data;
            this.loading = false;
          });
        }
      },

      category_menu: function(category) {},

      show_category: function(category) {
        this.current_category = category;
      },

      show_root_category: function(category) {
        this.current_root_category = category;
      },

      expand_all: function() {
        $("#category_tree").jstree("open_all", 0);
        $("#category_tree").jstree("deselect_all");
      },

      collapse_all: function() {
        $("#category_tree").jstree("close_all", 0);
        $("#category_tree").jstree("deselect_all");
        this.current_category = null;
      }
    }
  });

  hideAlert();
});

function initApplicationPlugins() {
  M.AutoInit();
}

document.addEventListener("turbolinks:load", function() {
  initApplicationPlugins();
});

var reloadWithTurbolinks = (function() {
  var scrollPosition;

  function reload() {
    scrollPosition = [window.scrollX, window.scrollY];
    Turbolinks.visit(window.location.toString(), { action: "replace" });
  }

  document.addEventListener("turbolinks:load", function() {
    if (scrollPosition) {
      window.scrollTo.apply(window, scrollPosition);
      scrollPosition = null;
    }
  });

  return reload;
})();
