<template>
  <div class="collection-item avatar" data-behavior="document">
    <span class="file-name" data-behavior="file-name">
      <span class="documentDescription">{{ description}}</span>
      <span class='document-menu' :id="'document-menu-' + id">
        <i class='material-icons'>more_vert</i>
      </span>
      <span class="documentCode">{{ code}}</span>
      <span v-if="file_version"  class="documentVersion">{{ file_version}}</span>
      
      <!--a :href="'/admin/documents/' + id " data-method="delete" data-remote="true" data-confirm="Are you sure?">
        <img class="close" :src="close_img_url" alt="close" >
      </a-->
    </span>
    <div class="item-content">
      <div class="img-container">
        <img data-behavior="pdf-preview" v-auth-image="preview_url_or_default" alt="" />
      </div>
      <div class="languages" data-behavior="languages-wrapper">
        
        
        <div v-if="language_for(languages, 'en')" class="version">
          <span data-behavior="language-name" class="language-name">en</span>
          <span data-behavior="language-button" class="language-button">
            <a :href="language_for(languages, 'en').direct_link" data-behavior="open-pdf" class="button button--get">GET</a>
          </span>
          <div class="close-placeholder">
            <a :href="'/admin/documents/' + id + '/languages?type=en'" data-method="delete" data-remote="true" data-confirm="Are you sure?"><img class="close" :src="close_img_url" alt="close" /></a>
          </div>
        </div>
        <div v-else class="version">
          <span data-behavior="language-name" class="language-name">en</span>
          <span data-behavior="language-button" class="language-button">
            <a :if="language_for(languages, 'en') == []" :href="'/admin/documents/' + id + '/languages/new?type=en'" data-remote="true" data-behavior="open-pdf" class="button button--upload">UPLOAD</a>
          </span>       
          <div class="close-placeholder">
          </div>   
        </div>
        
        
        
        <div v-if="language_for(languages, 'de')" class="version">
          <span data-behavior="language-name" class="language-name">de</span>
          <span data-behavior="language-button" class="language-button">
            <a :href="language_for(languages, 'de').direct_link" data-behavior="open-pdf" class="button button--get">GET</a>
          </span>
          <div class="close-placeholder">
            <a :href="'/admin/documents/' + id + '/languages?type=de'" data-method="delete" data-remote="true" data-confirm="Are you sure?"><img class="close" :src="close_img_url" alt="close" /></a>
          </div>
        </div>
        <div v-else class="version">
          <span data-behavior="language-name" class="language-name">de</span>
          <span data-behavior="language-button" class="language-button">
            <a :if="language_for(languages, 'de') == []" :href="'/admin/documents/' + id + '/languages/new?type=de'" data-remote="true" data-behavior="open-pdf" class="button button--upload">UPLOAD</a>
          </span>       
          <div class="close-placeholder">
          </div>   
        </div>
        
        
        
        <div v-if="language_for(languages, 'fr')" class="version">
          <span data-behavior="language-name" class="language-name">fr</span>
          <span data-behavior="language-button" class="language-button">
            <a :href="language_for(languages, 'fr').direct_link" data-behavior="open-pdf" class="button button--get">GET</a>
          </span>
          <div class="close-placeholder">
            <a :href="'/admin/documents/' + id + '/languages?type=fr'" data-method="delete" data-remote="true" data-confirm="Are you sure?"><img class="close" :src="close_img_url" alt="close" /></a>
          </div>
        </div>
        <div v-else class="version">
          <span data-behavior="language-name" class="language-name">fr</span>
          <span data-behavior="language-button" class="language-button">
            <a :if="language_for(languages, 'fr') == []" :href="'/admin/documents/' + id + '/languages/new?type=fr'" data-remote="true" data-behavior="open-pdf" class="button button--upload">UPLOAD</a>
          </span>       
          <div class="close-placeholder">
          </div>   
        </div>
        
        
        
        <div v-if="language_for(languages, 'es')" class="version">
          <span data-behavior="language-name" class="language-name">es</span>
          <span data-behavior="language-button" class="language-button">
            <a :href="language_for(languages, 'es').direct_link" data-behavior="open-pdf" class="button button--get">GET</a>
          </span>
          <div class="close-placeholder">
            <a :href="'/admin/documents/' + id + '/languages?type=es'" data-method="delete" data-remote="true" data-confirm="Are you sure?"><img class="close" :src="close_img_url" alt="close" /></a>
          </div>
        </div>
        <div v-else class="version">
          <span data-behavior="language-name" class="language-name">es</span>
          <span data-behavior="language-button" class="language-button">
            <a :if="language_for(languages, 'es') == []" :href="'/admin/documents/' + id + '/languages/new?type=es'" data-remote="true" data-behavior="open-pdf" class="button button--upload">UPLOAD</a>
          </span>       
          <div class="close-placeholder">
          </div>   
        </div>
        
        
        
        <div v-if="language_for(languages, 'it')" class="version">
          <span data-behavior="language-name" class="language-name">it</span>
          <span data-behavior="language-button" class="language-button">
            <a :href="language_for(languages, 'it').direct_link" data-behavior="open-pdf" class="button button--get">GET</a>
          </span>
          <div class="close-placeholder">
            <a :href="'/admin/documents/' + id + '/languages?type=it'" data-method="delete" data-remote="true" data-confirm="Are you sure?"><img class="close" :src="close_img_url" alt="close" /></a>
          </div>
        </div>
        <div v-else class="version">
          <span data-behavior="language-name" class="language-name">it</span>
          <span data-behavior="language-button" class="language-button">
            <a :if="language_for(languages, 'it') == []" :href="'/admin/documents/' + id + '/languages/new?type=it'" data-remote="true" data-behavior="open-pdf" class="button button--upload">UPLOAD</a>
          </span>       
          <div class="close-placeholder">
          </div>   
        </div>
        
        
      </div>
          
    </div>
  </div>
</template>

<script>
  
export default {
  props: ["id", "name", "preview_url", "languages", "no_preview_img_url", "close_img_url", "code", "file_version", "description"],

  computed: {
    preview_url_or_default: function(){
      
      if ((this.preview_url != null) && (this.preview_url != undefined))
        return this.preview_url
      else
        return this.no_preview_img_url
    }
  },
  
  mounted: function(){
    var vm = this
    $.contextMenu({
        selector: '#document-menu-' + vm.id, 
        trigger: 'left',
        document_id: vm.id,
        callback: function(key, options) {


          if (key === "delete"){
            $.ajax({
              url: "/admin/documents/" + options.document_id,
              dataType: "script",
              method: "DELETE",
            })
          }
          else if (key === "edit"){
            $.ajax({
              url: "/admin/documents/" + options.document_id + "/edit",
              dataType: "script"
            })
          }
        },
        items: {
            "edit": {name: "Edit", value: vm.id},
            "delete": {name: "Delete", value: vm.id},
        }
    });
  },
  
  methods: {
    
    language_for: function(languages, language_code){
      var filtered = languages.filter(function(e){ return e.language === language_code})
      return filtered[0]
    }
  }
};
</script>
